<template>
  <div class="p-grid">
    <Listbox
        ref="list"
        v-model="selectedItem"
        :filter="true"
        :filterPlaceholder="_t('label_search')"
        :multiple="false"
        :options="alarmRules"
        dataKey="id"
        listStyle="height:80vh"
        optionLabel="name"
        optionValue="id"
        style="width:20rem; margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px;"
        @change="selectAlarmRule($event.value)"
    >
      <template #empty>
        {{ _t('_ELEMENT_NOT_FOUND') }}
      </template>
      <template #header>
        <div style="padding: 1.5rem;">
          <h3 class="p-m-0">
            <Button v-if="!isEditable" v-tooltip.bottom="'Add alarm rule'"
                    class="p-button-rounded p-button-success p-button-text"
                    icon="pi pi-plus" style="float: right;" @click="initNewAlarmRuleDialog()"/>
            <strong>
              {{ _t('menu.alarm_rules') }}
            </strong>
          </h3>
          <MultiSelectableBreadcrumb ref="breadcrumbData" single-selection @change="setOrgId"/>
        </div>
        <SelectButton
            v-model="selectedTab"
            :options="tabs"
            class="alarm-rules__tabs"
            optionValue="value"
        >
          <template #option="slotProps">
            <p style="font-size: 10px !important; margin: 0;">{{ slotProps.option.label }}</p>
          </template>
        </SelectButton>
      </template>
      <template #option="slotProps">
        {{ slotProps.option.name }}
      </template>
    </Listbox>
    <transition name="slide-fade">
      <Card v-show="selectedAlarmRule.id !== null" class="p-col">
        <template #title>
          <div class="p-d-flex p-jc-between">
            <h3 class="p-m-0"><strong>{{ selectedAlarmRule.name }}</strong></h3>
            <div>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Cancel')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-times" @click="cancelChanges()"/>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Save')"
                      class="p-button-rounded p-button-text p-button-success"
                      icon="pi pi-check" @click="saveAlarmRule()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Edit')"
                      class="p-button-rounded p-button-text p-button-secondary"
                      icon="pi pi-pencil" @click="editAlarmRule()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Delete')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-trash" @click="deleteAlarmRuleDialog = true"/>
            </div>
          </div>
        </template>
        <template #content>
          <TabView>
            <TabPanel :header="_t('menu.general')">
              <div class="p-grid">
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_object_name') }}</p>
                </div>
                <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedAlarmRule.name }}</strong></p>
                  <InputText v-else v-model="selectedAlarmRule.name" maxlength="45" style="width:100%; margin-top:0;"
                             type="text"/>
                </div>
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_description') }}</p>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedAlarmRule.description ?? '...' }}</strong></p>
                  <InputText v-else v-model="selectedAlarmRule.description" maxlength="255"
                             style="width:100%; margin-top:0;" type="text"/>
                  <br/>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_organization') }}</p>
                </div>
                <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                  <OrganizationLink ref="orgLink" v-model="selectedAlarmRule" :editMode="isEditable"
                                    modelType="ALARM_RULE"/>
                </div>
                <div class="p-col-12 p-mt-2">
                  <h3 class="subhead">{{ _t('label_Content') }}</h3>
                </div>
                <div v-if="!isEditable" class="p-col-12">
                  <pre style="font-size: 12px;">
                    {{ selectedAlarmRule.content }}
                  </pre>
                </div>
                <div v-if="isEditable" class="p-col-12 p-text-right">
                  <Button :label="_t('label_Test_script')" class="p-button-secondary" @click="testScript()"/>
                </div>
                <div v-if="isEditable" class="p-col-6">
                  <p><strong>{{ _t('label_Script_source') }}</strong></p>
                  <Textarea v-model="selectedAlarmRule.content" rows="72" style="width: 100%;"/>
                </div>
                <div v-if="isEditable" class="p-col-6">
                  <p><strong>{{ _t('label_Input_variables') }}</strong></p>
                  <div class="p-grid">
                    <div class="p-col-4 stripedBg">
                      <p>_apId</p>
                    </div>
                    <div class="p-col-8 stripedBg">
                      <InputText v-model="vars.alarmPointId" placeholder="Alarm ID" style="width: 100%;"/>
                    </div>
                    <div class="p-col-4">
                      <p>_sysId</p>
                    </div>
                    <div class="p-col-8">
                      <InputText v-model="vars.systemId" placeholder="System ID" style="width: 100%;"/>
                    </div>
                    <div class="p-col-4 stripedBg">
                      <p>_text</p>
                    </div>
                    <div class="p-col-8 stripedBg">
                      <Textarea v-model="vars._text" :autoResize="true" placeholder="Alarm text*" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class="p-col-4">
                      <p>_event</p>
                    </div>
                    <div class="p-col-8">
                      <Dropdown v-model="vars.eventType" :options="events" option-label="label"
                                option-value="value" placeholder="Event type*" style="width: 100%;"/>
                    </div>
                    <div class="p-col-4 stripedBg">
                      <p>_priority</p>
                    </div>
                    <div class="p-col-8 stripedBg">
                      <InputNumber v-model="vars.priority" :max="99999999999" :min="1" placeholder="Priority*"
                                   showButtons style="width: 100%;"/>
                    </div>
                    <div class="p-col-4">
                      <p>_family</p>
                    </div>
                    <div class="p-col-8">
                      <InputText v-model="vars.family" placeholder="Family" style="width: 100%;"/>
                    </div>
                    <div class="p-col-4 stripedBg">
                      <p>_callerid</p>
                    </div>
                    <div class="p-col-8 stripedBg">
                      <InputText v-model="vars.callerId" placeholder="Caller ID" style="width: 100%;"/>
                    </div>
                  </div>
                  <p class="p-mt-4"><strong>{{ _t('label_Output_variables') }}</strong></p>
                  <div class="p-grid">
                    <div class=" p-col-4 stripedBg">
                      <p v-tooltip.bottom="'<span>Specify prefered text <br> <b>orgPath_ = \'orgPath\';</b>'  ">
                        orgPath_</p>
                    </div>
                    <div class=" p-col-8 stripedBg">
                      <Textarea v-model="vars.orgPath" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class="p-col-4 ">
                      <p v-tooltip.bottom="'<span>Specify preferred text: <br> <b>rule_ = \'Processing rule\';</b></span>'">
                        rule_</p>
                    </div>
                    <div class="p-col-8 ">
                      <Textarea v-model="vars.ruleName" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class=" p-col-4 stripedBg">
                      <p v-tooltip.bottom="'<span>Specify preferred text: <br> <b>name_ = \'Name\';</b></span>'">
                        name_</p>
                    </div>
                    <div class=" p-col-8 stripedBg">
                      <Textarea v-model="vars.name" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class="p-col-4 ">
                      <p v-tooltip.bottom="'<span>Specify preferred text: <br> <b>description_ = \'description\';</b></span>'">
                        description_</p>
                    </div>
                    <div class="p-col-8 ">
                      <Textarea v-model="vars.description" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class=" p-col-4 stripedBg">
                      <p v-tooltip.bottom="'<span>Specify preferred text: <br> <b>text_ = \'text\';</b></span>'">
                        text_</p>
                    </div>
                    <div class=" p-col-8 stripedBg">
                      <Textarea v-model="vars.text" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class="p-col-4 ">
                      <p v-tooltip.bottom="'<span>Specify preferred text: <br> <b>speechtext_ = \'speech text\';</b></span>'">
                        speechtext_</p>
                    </div>
                    <div class="p-col-8 ">
                      <Textarea v-model="vars.speechText" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class=" p-col-4 stripedBg">
                      <p v-tooltip.bottom="'<span>Specify numeric value: <br> <b>priority_ = 5;</b></span>'">
                        priority_</p>
                    </div>
                    <div class=" p-col-8 stripedBg">
                      <Textarea v-model="vars.priority" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class="p-col-4 ">
                      <p v-tooltip.bottom="'<span>Participant plan acknowledge mode: <br> ' +
                                    'NONE - None; <br> ' +
                                    'MEDIA - Remote; <br> ' +
                                    'PARTICIPANT - Local; <br> ' +
                                    'MEDIA_AND_PARTICIPANT - Remote and local <br> ' +
                                    '<b>acknowledge_mode_ =\'MEDIA_AND_PARTICIPANT\'</b></span>'"
                      >acknowledge_mode_</p>
                    </div>
                    <div class="p-col-8 ">
                      <Textarea v-model="vars.acknowledgeMode" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class=" p-col-4 stripedBg">
                      <p v-tooltip.bottom="'<span>Participant plan media usage type: <br>' +
                                    'VOICE - Voice only; <br>' +
                                    'TEXT - Text only; <br>' +
                                    'ALL - As configured <br>' +
                                    '<b>media_usage_ =\'ALL\'</b>'
                      ">media_usage_</p>
                    </div>
                    <div class=" p-col-8 stripedBg">
                      <Textarea v-model="vars.mediaUsageType" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class="p-col-4 ">
                      <p v-tooltip.bottom="'<span>Participant plan media processing type: <br>' +
                                    'BY_RANK - By rank; <br>' +
                                    'CONFERENCE_CALL - Conference call; <br>' +
                                    '<b>media_processing_ =\'BY_RANK\'</b>'
                      ">media_processing_</p>
                    </div>
                    <div class="p-col-8 ">
                      <Textarea v-model="vars.mediaProcessingType" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class=" p-col-4 stripedBg">
                      <p v-tooltip.bottom="'<span>Participant plan triggering: <br> ' +
                                    'WHEN_ON - When on; <br> ' +
                                    'WHEN_ON_AND_OFF - When on and off; <br> ' +
                                    '<b>triggering_ =\'WHEN_ON\'</b></span>'"
                      >triggering_</p>
                    </div>
                    <div class=" p-col-8 stripedBg">
                      <Textarea v-model="vars.triggering" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class="p-col-4 ">
                      <p v-tooltip.bottom="'<span>Specify name as value: <br> ' +
                                    '<b>alarmplan_ =\'name\'</b></span>'"
                      >alarmplan_</p>
                    </div>
                    <div class="p-col-8 ">
                      <Textarea v-model="vars.alarmPlanName" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class=" p-col-4 stripedBg">
                      <p v-tooltip.bottom="'<span>Specify name as value: <br> ' +
                                    '<b>rotational_ =\'name\'</b></span>'"
                      >rotational_</p>
                    </div>
                    <div class=" p-col-8 stripedBg">
                      <Textarea v-model="vars.rotationalCalendarName" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class="p-col-4 ">
                      <p v-tooltip.bottom="'<span>Specify numeric value: <br> <b>repeat_count_ = 5;</b></span>'">
                        repeat_count_</p>
                    </div>
                    <div class="p-col-8 ">
                      <Textarea v-model="vars.repeatCount" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                    <div class=" p-col-4 stripedBg">
                      <p v-tooltip.bottom="'<span>Specify numeric value: <br> <b>repeat_between_ = 5;</b></span>'">
                        repeat_between_</p>
                    </div>
                    <div class=" p-col-8 stripedBg">
                      <Textarea v-model="vars.repeatBetween" :autoResize="true" readonly="readonly" rows="1"
                                style="width: 100%;"/>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel :header="_t('label_Membership')">
              <div class="p-grid">
                <div v-if="alarmRulesTab" style="width: 100%; height: 100%">
                  <AlarmPointTable :alarmPointRows="alarmPointRows"
                                   :alarmPointValues="alarmPointValues"
                                   :alarmPoints="alarmPoints"
                                   :apCurrentPage="apCurrentPage"
                                   :apTotalRecords="apTotalRecords"
                                   :expandSidebarDialog="expandSidebarDialog"
                                   :getAlarmPlans="getAlarmPlans"
                                   :getAlarmPoint="getAlarmPoint"
                                   :loadInstructions="loadInstructions"
                                   :loading="loading"
                                   :tableDataKey="tableDataKey"
                                   :totalRecords="totalRecords"
                                   page="rule"
                                   @setApCurrentPage="setApCurrentPage"
                  />
                </div>
              </div>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </transition>
  </div>
  <Dialog v-model:visible="newAlarmRuleDialog" :style="{width: '450px'}">
    <template #header>
      <h3>{{ _t('label_Add_new_alarm_rule') }}</h3>
    </template>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-pencil"></i>
        </span>
          <InputText
              id="newAlarmRuleName"
              v-model="newAlarmRule.name"
              :class="newAlarmRule.errors ? 'p-invalid p-m-0' : 'p-m-0'"
              :placeholder="_t('Name')"
              maxlength="45"
          />
        </div>
        <small v-if="newAlarmRule.errors" class="p-error">{{ newAlarmRule.errors[0] }}</small>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-pencil"></i>
          </span>
          <InputText
              v-model="newAlarmRule.description"
              :placeholder="_t('label_description')"
              class="p-m-0"
              maxlength="255"
          />
        </div>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <OrganizationLink v-model="newAlarmRule" :editMode="true" :required-field="true"/>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newAlarmRuleDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createAlarmRule()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteAlarmRuleDialog">
    <template #header>
      <h3>Delete alarm rule</h3>
    </template>
    <p>Do you want to delete alarm rule <strong>{{ selectedAlarmRule.name }}</strong>?</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteAlarmRuleDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteAlarmRule()"/>
    </template>
  </Dialog>
</template>

<script>
import {getList} from '@/api/list'
import {createAlarmRule, deleteAlarmRule, getAlarmRule, testAlarmRule, updateAlarmRule} from "@/api/alarmRule";
import MultiSelectableBreadcrumb from "@/components/ixarma/MultiSelectableBreadcrumb";
import OrganizationLink from "../../components/ixarma/OrganizationLink";
import {mapActions, mapGetters} from 'vuex';
import {getAlarmPlanAlarmPoints} from "@/api/alarmPlan";
import {getInstructionFiles} from "@/api/attachments";
import {getAlarmPoint} from "@/api/alarmPoint";
import AlarmPointTable from '../../components/Table/alarm_point/AlarmPointTable'
import {getOrganizationColumns, getUserColumns} from "@/api/uiColumns";

export default {
  name: 'index',
  components: {MultiSelectableBreadcrumb, OrganizationLink, AlarmPointTable},
  data() {
    return {
      alarmPoints: [],
      alarmPointValues: [],
      apCurrentPage: 0,
      apTotalRecords: 0,
      totalRecords: 0,
      alarmPointRows: 100,
      expandSidebarDialog: true,
      loading: false,
      isEditable: false,
      instructions: [],
      selectedAlarmPoint: {
        id: null,
        icon: {
          name: '',
          color: ''
        }
      },
      isDeleted: false,
      newAlarmRuleDialog: false,
      editAlarmRuleDialog: false,
      deleteAlarmRuleDialog: false,
      alarmRules: [],
      alarmRulesTab: [],
      newAlarmRule: {
        name: '',
        description: '',
      },
      selectedItem: null,
      selectedAlarmRule: {
        id: null,
        name: '',
        description: '',
        content: '...'
      },
      srcOption: null,
      repeatableMonitored: false,
      rotationalCalendar: '',
      orgId: localStorage.getItem('orgId') ?? 1,
      vars: {},
      initVars: {
        alarmPointId: '',
        _text: '',
        text: '',
        eventType: 'K',
        priority: '1'
      },
      events: [
        {value: 'CREATE', label: 'Create'},
        {value: 'UPDATE', label: 'Update'},
        {value: 'DELETE', label: 'Delete'},
        {value: 'K', label: 'K'},
        {value: 'G', label: 'G'},
        {value: 'START_JOB', label: 'Start job'},
        {value: 'FINISH_JOB', label: 'Finish job'},
        {value: 'ACK', label: 'Ack'},
        {value: 'CHILD_CHANGE', label: 'Child change'},
        {value: 'FORWARD_EVENT_TO_BATCH_UPDATE', label: 'Forward event to batch update'},
      ],
      tabs: [],
      selectedTab: 'RULE',
      defaultContent: "// ------------  example ----------- \n" +
          "\n" +
          "if (_priority == 1 && _family == \"watchdog\"){\n" +
          "    orgPath_ = \"[Total system]-[]\";\n" +
          "    name_ = \"Name Alarm Point Default\";\n" +
          "    description_ = \"High priority description\";\n" +
          "    text_ = \"High priority text\";\n" +
          "    speechtext_ = \"High priority speechtext\";\n" +
          "    priority_ = \"1\" ;\n" +
          "    acknowledge_mode_ = \"MEDIA\";\n" +
          "    media_usage_ = \"TEXT\";\n" +
          "    triggering_ = \"WHEN_ON_AND_OFF\";\n" +
          "    media_processing_ = \"CONFERENCE_CALL\";\n" +
          "    alarmplan_ = \"High priority alarmplan\";\n" +
          "    rotational_ = \"High priority rotational\";\n" +
          "    repeat_count_ = \"5\";\n" +
          "    repeat_between_ = \"2\";\n" +
          "// optional. being applied to created alarm by alarm template\n" +
          "    rule_ = \"Processing rule 1\";\n" +
          "} else {\n" +
          "    description_ = \"Low priority description\";\n" +
          "    text_ = \"Low priority text\";\n" +
          "    speechtext_ = \"Low priority speechtext\";\n" +
          "    priority_ = \"10\";\n" +
          "    acknowledge_mode_ = \"NONE\";\n" +
          "    media_usage_ = \"VOICE\";\n" +
          "    media_processing_ = \"BY_RANK\";\n" +
          "    triggering_ = \"WHEN_ON\";\n" +
          "    alarmplan_ = \"Low priority alarmplan\";\n" +
          "    rotational_ = \"Low priority rotational\";\n" +
          "    repeat_count_ = \"2\";\n" +
          "    repeat_between_ = \"1\";\n" +
          "// optional. being applied to created alarm by alarm template\n" +
          "    rule_ = \"Processing rule 1\";\n" +
          "}",
      selectedAlarmPointItem: null,
      tableDataKey: null,
      activeTab: ''
    }
  },
  created() {
    this.tabs = [{label: this._t('label_Rules'), value: 'RULE'}, {
      label: this._t('label_Plans'),
      value: 'PLAN'
    }, {label: this._t('label_Schedules'), value: 'SCHEDULE'}]
  },
  mounted() {
    this.checkOrgId()
    this.getAlarmRules()

    if (this.$route.params.id) {
      this.selectAlarmRule(this.$route.params.id)
    }

    getUserColumns().then((userRes) => {
      if (userRes.data) {
        for (const [key, value] of Object.entries(userRes.data)) {
          if (key === 'alarmPointItems') this.alarmPointValues = value
        }
      } else {
        getOrganizationColumns().then((res) => {
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key === 'alarmPointItems') this.alarmPointValues = value
            }
          }
        }).catch((err) => console.log('error', err))
      }
    }).catch((err) => console.log('error', err))
  },

  methods: {
    ...mapActions({
      'callCalendars': 'rotationalCalendar/callCalendars',
      'callAlarmPointCalendar': 'alarmpoints/callCalendar',
      'callCalendarById': 'rotationalCalendar/callCalendarById',
    }),
    getAlarmRulesForTab() {
      if (this.selectedAlarmRule.id) {
        getAlarmPlanAlarmPoints(this.selectedAlarmRule.id, '', 'ALARM_RULE').then((response) => {
          this.alarmPoints = response.data.list
          this.apTotalRecords = response.data.count
          this.alarmPointsOriginal = response.data.list
          this.loading = false;
        })
      }
    },
    setOrgId(id) {
      this.orgId = id
      this.getAlarmRules()
      this.selectedAlarmRule = {
        id: null,
        name: '',
        description: '',
      }
      this.isEditable = false
    },
    initNewAlarmRuleDialog() {
      this.newAlarmRuleDialog = true
      this.newAlarmRule = {
        name: '',
        description: '',
        orgName: this.orgName,
        orgId: this.orgId,
        content: this.defaultContent,
      }
    },
    selectAlarmRule(id) {
      if (id && this.selectedTab === 'RULE') {
        getAlarmRule(id).then((response) => {
          this.selectedAlarmRule = response.data
          this.vars = {
            alarmPointId: '',
            _text: '',
            text: '',
            eventType: 'K',
            priority: '1'
          }
          this.isEditable = false
        })
      }
    },
    cancelChanges() {
      this.isEditable = false
      if (this.selectedAlarmRule.id && !this.selectedAlarmRule.id.startsWith('_')) {
        this.selectAlarmRule(this.selectedAlarmRule.id)
      } else {
        this.selectedAlarmRule.id = null
      }
    },
    getAlarmRules() {
      getList('ALARM_RULE', 0, '', 1000).then((response) => {
        this.alarmRules = response.data.list.sort((a, b) => a.name.localeCompare(b.name))
        this.loading = false
        // this.$refs.cf.refresh(this.alarmRules)
      })
    },
    editAlarmRule() {
      this.isEditable = !this.isEditable
    },
    saveAlarmRule() {
      let data = {}
      if (!this.selectedAlarmRule.id || this.selectedAlarmRule.id.startsWith('_')) {
        data = Object.assign({}, this.newAlarmRule)
      } else {
        data = Object.assign({}, this.selectedAlarmRule)
      }

      if (!data.name || !data.name.length) {
        this.$root.showMessage('Please, specify alarm rule name.', 'error')
        return false
      }

      if (data.name.length > 45) {
        this.$root.showMessage('alarm rule name should be no longer 45 characters.', 'error')
        return false
      }

      if (data.description && data.description.length > 255) {
        this.$root.showMessage('alarm rule description should be no longer 255 characters.', 'error')
        return false
      }

      if (data.id && data.id.startsWith('_')) {
        delete data.id
        createAlarmRule(data).then((response) => {
          this.selectedAlarmRule = response.data
          this.isEditable = false
          this.getAlarmRules()
        }).catch((error) => {
          console.log(error)
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
        })
      } else {
        this.$refs.orgLink.moveToOrg((increaseVersion) => {
          if (increaseVersion) data.version++
          updateAlarmRule(data).then((response) => {
            this.selectedAlarmRule = response.data
            this.isEditable = false
            this.getAlarmRules()
          }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        })
      }
    },
    deleteAlarmRule() {
      this.deleteAlarmRuleDialog = false
      if (this.selectedAlarmRule.id.startsWith('_')) {
        this.selectedAlarmRule = {
          id: null,
          name: '',
          description: ''
        }
      } else {
        deleteAlarmRule(this.selectedAlarmRule.id).then(() => {
          this.selectedAlarmRule = {
            id: null,
            name: '',
            description: ''
          }
          this.getAlarmRules()
        }).catch((error) => {
          console.log(error)
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage((data.reason ?? 'Error deleting alarm rule'), 'error')
        })
      }
    },
    createAlarmRule() {
      this.newAlarmRule.errors = []
      if (!this.newAlarmRule.orgId) {
        this.newAlarmRule.orgId = localStorage.getItem('orgId');
      }

      if (!this.newAlarmRule.name) {
        this.newAlarmRule.errors.push('Name: may not be empty.')
        return false;
      }
      if (this.newAlarmRule.name.length < 2) {
        this.newAlarmRule.errors.push('Name: Length is less than allowable minimum of 2.')
        return false;
      }

      this.newAlarmRuleDialog = false

      this.selectedAlarmRule = this.newAlarmRule
      this.selectedAlarmRule.id = '_'
      this.isEditable = true
    },
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.getAlarmRules()
        }
      }, 100);
    },
    testScript() {
      if (this.vars._text.length > 0) {
        this.vars.text = this.vars._text
      }
      let data = Object.assign({
        content: this.selectedAlarmRule.content
      }, this.vars)
      testAlarmRule(data).then((response) => {
        this.vars = Object.assign(this.vars, response.data)
        this.vars.acknowledgeMode = this._t("_" + this.vars.acknowledgeMode)
        this.vars.mediaUsageType = this._t(this.vars.mediaUsageType)
        this.vars.mediaProcessingType = this._t(this.vars.mediaProcessingType)
        this.vars.triggering = this._t(this.vars.triggering)
        this.$root.showMessage('Alarm rule checked.', 'success')
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ? ': ' + data.reason.split('|').join(", ") : ''), 'error')
      })
    },
    getAlarmPlans() {
      getList('ALARMPLAN', 0, '', 1000, this.orgId).then((response) => {
        this.alarmRules = response.data.list.sort((a, b) => a.name.localeCompare(b.name))
      })
    },
    loadInstructions() {
      getInstructionFiles('')
          .then(res => {
            if (res) {
              this.instructions = this.getInstructionsRecursive(res.data.instructions)
              if (this.instructions.length) {
                this.instructions.sort((a, b) =>
                    a.org.fullOrgPath.localeCompare(b.org.fullOrgPath)
                    || a.name.localeCompare(b.name)
                )
              }
            }
          })
    },
    changeTab() {
      switch (this.selectedTab) {
        case 'PLAN':
          this.getAlarmPlans();
          break;
        case 'SCHEDULE':
          this.callCalendars(this.orgId)
              .then(() => {
                this.alarmRules = this.getCalendars.sort((a, b) => a.name.localeCompare(b.name))
              });
          break;
        default:
          this.getAlarmRules();
          break;
      }
    },
    getAlarmPoint(id, setIsEditable = false) {
      getAlarmPoint(id).then((response) => {
        this.isEditable = setIsEditable
        this.selectedAlarmPoint = response.data
        if (this.selectedAlarmPoint.id && this.selectedAlarmPoint.id !== 'null') {
          this.isDeleted = this.selectedAlarmPoint.deleted
          if (this.$refs.reportHistory) {
            this.$refs.reportHistory.getHistoryByAlarmId(
                this.selectedAlarmPoint.id,
                {
                  alarmName: null,
                  alarmPointId: this.selectedAlarmPoint.alarmPointId,
                  statuses: [],
                  systemId: null,
                  triggeredBy: null,
                },
                {
                  hours: this.hoursDuration,
                  option: 'HOURS_DURATION'
                }
            )
          }
        }

        if (this.selectedAlarmPoint.repeatMonInterval && this.selectedAlarmPoint.repeatMonInterval != 0) {
          this.repeatableMonitored = true
        }

        this.getAlarmPlan(this.selectedAlarmPoint.alarmPlanId)

        this.callCalendarById(this.selectedAlarmPoint.rotationalCalendarId)
        this.callAlarmPointCalendar(this.selectedAlarmPoint.id)
        this.rotationalCalendar = '';
        try {
          this.selectedAlarmPoint.icon = JSON.parse(this.selectedAlarmPoint.icon)
        } finally {
          if (!this.selectedAlarmPoint.icon || typeof this.selectedAlarmPoint.icon !== 'object') {
            this.selectedAlarmPoint.icon = {
              color: '#989898',
              name: 'mdi mdi-alert-outline'
            }
          }
        }
        this.srcOption = 'EXIST'
        // this.activeTab = 0
        this.$forceUpdate()
        this.loading = false
      })
    },
    setApCurrentPage(val) {
      this.apCurrentPage = val
    },
  },
  computed: {
    ...mapGetters({
      'getCalendars': 'rotationalCalendar/getCalendars',
    }),
  },
  watch: {
    selectedTab: function () {
      this.changeTab();
    },
    selectedAlarmRule() {
      this.getAlarmRulesForTab()
    },
  }
}
</script>

<style scoped>
.alarm-rules__tabs {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

:deep(.alarm-rules__tabs .p-button) {
  width: 33%;
}
</style>
